import React from "react";
import { motion } from "framer-motion";

const Footer = (props) => {
  return (
    <div id="main-footer" className={props.clicked ? "not-homepage-footer": ""}>
      {props.clicked ? (        
          <motion.button whileHover={{ scale: props.currentAnswer!==""?1.1:1 }} whileTap={{ scale: props.currentAnswer!==""?0.9:1 }} onClick={props.nextPage} className={props.currentAnswer!==""?"button-rounded-green":"button-rounded-green disabled"}>Yes that's me</motion.button>        
      ) : (
        <motion.div initial = {{  x:-200}} animate = {{ x:0}} transition={{ duration: 1 }}>
          <button className="left-button">
            Discover your true self
          </button>
          <button onClick={props.toggleClicked} className="right-button">
            Ok, let's do this!
            <div className="arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.293"
                height="14.172"
                viewBox="0 0 17.293 14.172"
              >
                <g
                  id="Group_1"
                  data-name="Group 1"
                  transform="translate(-325.122 -696.469)"
                >
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M.075.5H13.739"
                    transform="translate(326.047 703.085)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_2_Copy_3"
                    data-name="Path 2 Copy 3"
                    d="M.84.383,6.482,6.024"
                    transform="translate(334.518 697.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_2_Copy_4"
                    data-name="Path 2 Copy 4"
                    d="M.84-.383,6.482-6.024"
                    transform="translate(334.518 709.609)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </button>
        </motion.div>
      )}
    </div>
  );
};

export default Footer;
