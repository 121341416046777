import React from 'react';
import QuestionCounter from './QuestionCounter';
import { motion } from "framer-motion";

const Header = ( props ) => {
         
         return (
         <motion.div id="header-container" initial = {{ opacity: 0, y:200}} animate = {{ opacity:1, y:0}} transition={{ duration: 1 }}>
         {props.clicked ?
         <>
         <QuestionCounter currentPage={props.currentPage}/>
         <h1 className="question-title">{props.questions[props.currentPage].content}</h1></>:
         <>
         <svg xmlns="http://www.w3.org/2000/svg" width="106" height="29" viewBox="0 0 106 29">
         <text id="CCMEDIA" transform="translate(0 21)" fill="#29292b" fontSize="20" fontFamily="Jost" fontWeight="300" letterSpacing="0.1em"><tspan x="0" y="0">CC</tspan><tspan y="0" fontWeight="700">MEDIA</tspan></text>
         </svg>
         <h1 className="homepage-title">Find out what your lockdown spirit animal is with our personality quiz.</h1>
         </>
         }
         </motion.div>
         );
}

export default Header;