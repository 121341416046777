import React, { Component } from "react";
import "./App.css";
import Header from "./Header/Header";
import "./Header/Header.css";

import Footer from "./Footer/Footer";
import "./Footer/Footer.css";

import Content from "./Content/Content";
import "./Content/Content.css";

import Results from "./Results/Results";
import "./Results/Results.css";
import TagManager from 'react-gtm-module'
// UA-167100808-1  - Google Analytics code
// GTM-WQ3FSN3 - Google Tag Manager
const tagManagerArgs = {
    gtmId: 'GTM-WQ3FSN3'
}

TagManager.initialize(tagManagerArgs)

class App extends Component {

  componentDidMount = () => {

    
    TagManager.dataLayer({
      dataLayer: {
        event: 'virtualPageView',
        virtualPageTitle: "Home",
        virtualPageUrl: "/"
      }
    });

  }

  getPersonalityData = () => {
    let obj = this.state.results[0];
    let personality = Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b);
    let personality_data = [];
    switch (personality) {
      case "neatfreakgermaphobe":
        personality_data = [{
          slug: "neatfreakgermaphobe",
          image: <img className="personality-image" src={require("./Content/images/personalities/neatfreakgermaphobe.png")} alt='The Neat Freak / GermaPhobe'/>,
          title: `The Neat Freak Germaphobe`,
          name: `Kathryn The Cat`,
          text: `Kathryn the cat is scared of ... Covid-19, running out of toilet roll, people with dry coughs, Chinese food, a hole in her mask, running out of hand sanitiser, having less than 5 showers per day, people with nasally voices, the end of the days and everything in general. That's you that is. You're Kathryn.`
        }]
        return personality_data;
      case "slob":
        personality_data = [{
          slug: "slob",
          image: <img className="personality-image" src={require("./Content/images/personalities/slob.png")} alt='The Slob'/>,
          title: `The Slob`,
          name: `Henry The Hippo`,
          text: `For Henry, isolation has brought about an enlightened way of thinking... "If nobody is going to see or smell you, whats the fucking point?" Henry is now carving an irreversible arse grove into his sofa, whilst watching re-runs of Fort Boyard. This unlikely omnibus came to pass after the zapper eroded from overuse, he refuses to change the channel manually.`
        }]
        return personality_data;
      case "snitch":
        personality_data = [{
          slug: "snitch",
          image: <img className="personality-image" src={require("./Content/images/personalities/snitch.png")} alt='The Snitch'/>,
          title: `The Snitch`,
          name: `Mary The Meerkat`,
          text: `Mary likes to spend her day judging "Covid-iots" on social media (101 have already blocked her). Her trusty notepad is the perfect tool to log that step out of line. "Whats's that Brenda? Walking Buttons for the second time today? In the fucking book you go!"`
        }]
        return personality_data;
      case "gamer":
        personality_data = [{
          slug: "gamer",
          image: <img className="personality-image" src={require("./Content/images/personalities/gamer.png")} alt='The Gamer'/>,
          title: `The Gamer`,
          name: `Barry The Bear`,
          text: `Barry is in paradise. It has now become socially acceptable to stay indoors and play Modern Warfare for 26 hours straight. Equipped with a headset to communicate with angry 14 year olds, Barry sees no need to venture outside, even if his legs are numb from a lack of blood circulation.`
        }]
        return personality_data;
      case "alcoholic":
        personality_data = [{
          slug: "alcoholic",
          image: <img className="personality-image" src={require("./Content/images/personalities/alcoholic.png")} alt='The Alcoholic'/>,
          title: `The Alcoholic`,
          name: `Ollie The Octopus`,
          text: `Ollie is a creature of simple tastes, beer, red wine and jagermeister. If his surplus of shop bought alcohol was to run dry (very unlikely), Ollie has a 30 year old bottle of ouzo for safe measure. Times are tough and life is best viewed though a fish-eyed lense, a bit of sick in a bucket or kitchen drawer is a small price to pay.`
        }]
        return personality_data;
      case "homeathlete":
        personality_data = [{
          slug: "homeathlete",
          image: <img className="personality-image" src={require("./Content/images/personalities/homeathlete.png")} alt='The Home Athlete'/>,
          title: `The Home Athlete`,
          name: `Leroy The Leopard`,
          text: `Leroy refuses to let isolation get in the way of his vigorous workout routine. When he's not harassing clients to join him on a zoom workout, he's writing threatening emails to Joe Wicks about his burpees posture and uploading motivational quotes to his Instagram.`
        }]
        return personality_data;
      case "quizzer":
        personality_data = [{
          slug: "quizzer",
          image: <img className="personality-image" src={require("./Content/images/personalities/quizzer.png")} alt='The Quizzer'/>,
          title: `The Quizzer`,
          name: `Otis The Owl`,
          text: `Otis is a purveyor of wisdom, knowledge and useless facts. He is a firm believer that sobriety is the key to winning and will not drink for 72 hours before a quiz. He strongly dislikes cheaters and is currently developing an algorithm for a quiz with answers that are “un-searchable”.`
        }]
        return personality_data;
      case "socialmediaobsessed":
        personality_data = [{
          slug: "socialmediaobsessed",
          image: <img className="personality-image" src={require("./Content/images/personalities/socialmediaobsessed.png")} alt='The Social Media Obsessed'/>,
          title: `The Social Media Obsessed`,
          name: `Fay The Flamingo`,
          text: `Isolation has provided Fay with a unique opportunity. After being furloughed from her job at Primark, she can now focus 100% on the arts of Instagram, Snapchat and TikTok. Her public persona is looking fleek "all day ere'day" but is a self confessed "geek" because she loves the Avengers.`
        }]
        return personality_data;
      default:
        return "Not decided yet.";
    }
  }

  activateconfetti = () => {
    this.setState({
      conffetti: !this.state.conffetti
    });
  }
  straightToFinalPage = () => {
    this.setResult("snitch");
    this.setState({
      currentPage: 9,
      showResults: true
    });
  }

  nextPage = () => {
    if(this.state.currentAnswer !== "" || this.state.clicked === false){
    if (this.state.currentPage < 8) {
      if(document.getElementById("style-1") !== null){
      document.getElementById("style-1").scrollTop = 0;
      
      var container = document.querySelector('[id="simplebarobject"] [class="simplebar-content-wrapper"]');
      container.scrollTo({ top: 0, behavior: "smooth" });
   
      this.setResult(this.state.currentAnswer);
      
      this.setState({
        currentAnswer: ""
      });
    }
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
      
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageView',
          virtualPageTitle: "Question " +String(Number(this.state.currentPage)+1),
          virtualPageUrl: "/question-"+String(Number(this.state.currentPage)+1)
        }
      });

    } else {
      this.setState({
        showResults: true
      });
    }
  } else {
    alert("You need to choose an answer!");
  }
  };

  toggleClicked = () => {
    this.nextPage();
    this.state.clicked
      ? this.setState({
          clicked: false,
        })
      : this.setState({
          clicked: true,
        });
  };



  setResult = (answered) => {
    this.setState({    
      results: [{
        ...this.state.results[0],
        [answered]: this.state.results[0][answered] + 1
      }]

    });
  }

  getResults = () => {
    console.log(this.state.results);
  }

  getAnswers = () => {
    console.log(this.state.questions[0][this.state.currentPage].answers);
  }

  setCurrentAnswer = (q) => {
    this.setState({
      currentAnswer: q
    });
    this.forceUpdate();
  }

  setClassForButtonOfAnswer = (q) => {
    if(this.state.currentAnswer===q){
      return "active";
    } else {
      return null;
    }
  }

  state = {
    currentAnswer: "",
    clicked: false,
    currentPage: Number(0),
    showResults: false,

    results: [{
      "neatfreakgermaphobe":0,
      "slob":0,
      "snitch":0,
      "gamer":0,
      "alcoholic":0,
      "homeathlete":0,
      "quizzer":0,
      "socialmediaobsessed":0,
    }],

    questions: [{
      1: {
        content: "How likely are you to report a Neighbour for not following social distancing rules?",
        answers: [
          ["Yesterday they walked within a 6ft radius of my dog. I'm not an unreasonable person, but 20 years each seems appropriate.", "neatfreakgermaphobe"],
          ["Don't like them anyway, might do it to shit them up. SHOULDN'T HAVE MOVED MY WHEELIE BIN SHOULD YOU SANDRA.", "snitch"],
          ["I only settle debates 1 on 1 in the Gulag.", "gamer"],
          ["Too busy trying to get sub 20 mins in my 5k run on strava.", "homeathlete"],
        ]},
      2: {
        content: "How many times a day do you wash your hands?",
        answers: [
          ["I am 98% hand sanitizer ", "neatfreakgermaphobe"],
          ["Day 1 I was on it, day 298 I only care about the next virtual pub quiz.", "quizzer"],
          ["I wash my hands like I've got a club stamp I don’t want mum to see.", "socialmediaobsessed"],
          ["Never. What doesn’t kill you makes you stronger. *Begins to rap Kanye West Stronger*", "homeathlete"],
        ]},
      3: {
        content: "How likely are you to attend a virtual quiz?",
        answers: [
          ["Just call me Chris Tarant", "quizzer"],
          ["It’s an excuse to drink and I would class myself as a semi pro alcoholic at this stage.", "alcoholic"],
          ["I’m so over quizzes.. Just put me on furlough.", "slob"],
          ["Can’t quiz, I’m in the Gulag.", "gamer"],
        ]},
      4: {
        content: "How many home workouts do you do each week?",
        answers: [
          ["If I could do less than 0, I would. I do wear activewear to (drink alcohol) write my quizzes though.", "quizzer"],
          ["1...maybe 2 if mum says I look fat on video chat.", "socialmediaobsessed"],
          ["3 - 4, which actually means 1-2 but im lying to myself because i'm in denial.", "slob"],
          ["Twice a day. Every day. I am a pro athlete and everyone on my Instagram will be subjected to my workouts.", "homeathlete"],
        ]},
      5: {
        content: "Do you know how much alcohol you drink every week?",
        answers: [
          ["What’s the point of counting. I’m white girl wasted all the time.", "alcoholic"],
          ["None. My body is my temple, alcohol is the devil.", "homeathlete"],
          ["A few… Trump said disinfectant kills corona and alcohol is basically disinfectant right?", "neatfreakgermaphobe"],
          ["Not a lot personally, but I think my team are drinking wine out of their coffee mugs every morning.", "snitch"],
        ]},
      6: {
        content: "Do you know what the gulag is?",
        answers: [
          ["Somewhere my boyfriend goes and shouts at 12 year olds.", "snitch"],
          ["I believe it's Hungarian for Beef Stew. Madeleine Shaw made it, I swear.", "socialmediaobsessed"],
          ["I’ve mastered WarZone. Only the weak end up in the Gulag.", "gamer"],
          ["I don’t know anything anymore. I’m drunk.", "alcoholic"],
        ]},
      7: {
        content: "How many TikTok dances have you learnt?",
        answers: [
          ["Who’s TikTok? Can I use them in a quiz?", "quizzer"],
          ["Carole Baskin. Killed her husband, whacked him.", "slob"],
          ["I've considered retiring from my desk job and becoming a TikTok influencer. Probably be on ‘I'm A Celeb next’ year. ", "socialmediaobsessed"],
          ["Can’t TikTok, I’m in the gulag ", "gamer"],
        ]},
      8: {
        content: "How messy is your home right now?",
        answers: [
          ["I am Kim from Kim and Aggies, “How clean is your house”. Not Aggie because she turned out to be a right piece of work.", "neatfreakgermaphobe"],
          ["I started off using concentrated alcohol for cleaning but now i'm drinking it.", "alcoholic"],
          ["I have children and it turns out they are little pricks", "snitch"],
          ["Put it this way, I haven't showered in 3 days what do you think my house looks like?", "slob"],
        ]},
     
    }],

  };
 
  render() {


    return (
      <div className={this.state.showResults ? "main results-page":"main page"+this.state.currentPage}>
        {/* <div className="admin-helpers">
          <button onClick={this.getResults}>Show results in console</button>
          <button onClick={()=> {this.setResult("alcoholic")}}>Set Alcoholic as answered</button>
          <button onClick={this.getAnswers}>Get Answers for current question in console</button>
          <button onClick={this.getPersonalityImage}>Show me anwers</button>
        </div> */}
        {this.state.showResults ?
        <>
        <Results getPersonalityData={this.getPersonalityData()[0]} conffetti={this.state.conffetti} activateconfetti={this.activateconfetti}/>
        </>
        :
        <>
        <Header
          questions={this.state.questions[0]}
          currentPage={this.state.currentPage}
          toggleClicked={this.toggleClicked}
          clicked={this.state.clicked}
        />
        <Content setClassForButtonOfAnswer={this.state.currentAnswer} setCurrentAnswer={this.setCurrentAnswer} setResult={this.setResult} questions={this.state.questions[0]} currentPage={this.state.currentPage} isHomepage={this.state.clicked===false ? true : false}  />
        <Footer
        
          currentAnswer={this.state.currentAnswer}
          straightToFinalPage={this.straightToFinalPage}
          nextPage={this.nextPage}
          toggleClicked={this.toggleClicked}
          clicked={this.state.clicked}
        />
        </>
      }
      </div>
    );
  };
};

export default App;
