import React from "react";

const QuestionCounter = (props) => {
  return (
    <div className="question-counter">
      {props.currentPage === 8 ? (
        <>LAST QUESTION WOOO</>
      ) : (
        <>
          QUESTION{" "}
          <span className="current-page-counter">{props.currentPage}</span>
          <span className="maximum-page-counter">/8</span>
        </>
      )}
    </div>
  );
};

export default QuestionCounter;
