import React, { Component } from 'react'
import { motion } from "framer-motion";
import SimpleBar from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';
export default class Content extends Component {

  getClassForButton = (q) => {
    if(this.props.setClassForButtonOfAnswer===q) {
      return "active";
    } else {
      return null;
    }
  }

  render() {
    return (
      <>
      <div className="flex-wrapper">
      <div id="content" className={this.props.isHomepage ? "homepage-content" : "content"}>        
      {this.props.isHomepage ?<motion.img src={require("./images/homepage.png")} alt="Find out what your lockdown spirit animal is with our personality quiz." className="homepageWelcomeImage" initial = {{ opacity: 0, x:200}} animate = {{ opacity:1, x:0}} transition={{ duration: 1 }}/>:  
      <>          
      <div id="style-1" className="list-of-answers">
      <SimpleBar id="simplebarobject" style={{ width: "100%", height: "100%" }} data-simplebar-auto-hide="false">
      {this.props.questions[this.props.currentPage].answers.map((q,i) => {
        return <button className={this.getClassForButton(q[1])} onClick={()=> {this.props.setCurrentAnswer(q[1]);}} key={i}><span className="indicator">{i+1}</span>{q[0]}<span className="hidden">{this.getClassForButton(q[1])}</span></button>;
      })}
      </SimpleBar>
      </div>
      </>
      }     
      
      </div>
            
    </div>
      </>
    )
  }
}