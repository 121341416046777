import React, { Component } from 'react'
import Confetti from 'react-dom-confetti';
import TagManager from 'react-gtm-module'

const config = {
  angle: 0,
  spread: "360",
  startVelocity: 45,
  elementCount: 100,
  dragFriction: 0.1,
  duration: 9000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};


export default class Results extends Component {
        componentDidMount(){
          this.props.activateconfetti();

          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageView',
              virtualPageTitle: this.props.getPersonalityData.title,
              virtualPageUrl: "/results-"+String(this.props.getPersonalityData.title).replace(/\s+/g, '-').toLowerCase()
            }
          });
        }
        sendshareevent(button) {
    
          TagManager.dataLayer({
            dataLayer: {
              event: 'shareButtonClicked',
              shareButtonLabel: button,
            }
          });
      
        }

         render() {
                  return (
                           <>            
                           <div className="results-card">
                             <div  className="positioncenter">
                           <Confetti active={ this.props.conffetti } config={ config }/>
                           </div>
                                    <h1 className="personality-title-name">You're <span className="wrapper-of-personality-name">{this.props.getPersonalityData.name}</span></h1>
                                   {this.props.getPersonalityData.image}  

                                  <div style={{paddingBottom: "10px"}}>SHARE YOUR RESULTS:</div>
                                  <a onClick={()=>{this.sendshareevent("facebook");}} href={"https://www.facebook.com/sharer.php?u=https://isolationspiritanimal.com/"+this.props.getPersonalityData.slug+".html"}><img src="/share_to_facebook.png" alt="Share to FaceBook" className="full-width"/></a>
                          
                                  <a  onClick={()=>{this.sendshareevent("whatsapp");}} href={"whatsapp://send?text=My isolation spirit animal is "+this.props.getPersonalityData.name+" 😂 Now it's time to find out yours! https://isolationspiritanimal.com/"} data-action="share/whatsapp/share"><img src="/share_to_whatsapp.png" alt="Share to FaceBook" className="full-width"/></a>


                                   <div className="short-description">
                                   {this.props.getPersonalityData.text} 
                                   </div>

                           </div>                           
                           </>
                  )
         }
}
